<template>
  <div class="more-project-container">
    <div class="more-header-container">诚越</div>
    <div class="more-header-txt-container">
      <div class="header-txt-nav-container">
        <div class="nav-txt" style="cursor:pointer" @click="returnUNproject">新闻中心</div>
        <div class="nav-txt">></div>
        <div class="nav-txt" style="cursor:pointer" @click="returnUNproject">项目动态</div>
        <div class="nav-txt">></div>
        <div class="nav-txt nav-content">正文</div>
      </div>
      <div class="header-title">{{UNProjectInfo.title}}</div>
      <div class="header-time">{{UNProjectInfo.createTime}}</div>
    </div>
    <div v-html="UNProjectInfo.allcontent" class="more-content-container"></div>
    <div class="btn-container">
      <div class="pre-container" @click="clickPre">
        <div class="btn">
          <div class="btn-txt">{{left}}</div>
        </div>
        <div class="txt" style="padding-left:1rem">上一篇</div>
      </div>
      <div class="next-container" @click="clickNext">
        <div class="txt" style="padding-right:1rem">下一篇</div>
        <div class="btn">
          <div class="btn-txt">{{right}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UtilPaths from '../../utils/UtilsPath'
import axios from 'axios'
export default {
  data () {
    return {
      InfoIndex: 0,
      UNProjectInfo: [],
      left: '<',
      right: '>'
    }
  },
  async created () {
    let tempIndex = this.$route.params.index + ((this.$route.params.currentpage - 1) * 4)
    let tempAllInfos = await axios.get(UtilPaths.getProjectAllInfo)
    this.$data.InfoIndex = tempIndex
    let tempInfo = this.$route.params.info
    this.$data.UNProjectInfo = tempInfo
    this.$store.commit('setUNAllProjectItems', { unAllProjectItems: tempAllInfos.data })
  },
  methods: {
    clickPre () {
      if (this.$data.InfoIndex === 0) {
        alert('没有了哦')
      } else {
        let tempIndex = this.$data.InfoIndex
        let tempInfos = this.$store.getters.getUNAllProjectItems
        this.$data.InfoIndex = tempIndex - 1
        this.$data.UNProjectInfo = tempInfos[tempIndex - 1]
        window.scrollTo(0, 0)
      }
    },
    clickNext () {
      let tempIndex = this.$data.InfoIndex
      let tempInfos = this.$store.getters.getUNAllProjectItems
      if (tempIndex === tempInfos.length - 1) {
        alert('没有了哦')
      } else {
        this.$data.InfoIndex = tempIndex + 1
        this.$data.UNProjectInfo = tempInfos[tempIndex + 1]
        window.scrollTo(0, 0)
      }
    },
    returnUNproject () {
      this.$router.push({ name: 'UNProject', params: { index: 1 } })
    }
  }
}
</script>

<style scoped>
.more-project-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.more-header-container{
  height: 3.03rem;
  width: 100%;
  font-size: 1.84rem;
  text-align: center;
  color: #2d3e50;
  border-bottom: 1px #dfdddd solid;
}
.more-header-txt-container{
  display: flex;
  width: 40.64rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px #dfdddd solid;
}
.header-txt-nav-container{
  display: flex;
  width: 16.19rem;
  margin-top: 1.75rem;
}
.nav-txt{
  font-size: 0.85rem;
  color: #333333;
  letter-spacing: 3.5px;
}
.nav-content{
  color: #68d5d0
}
.header-title{
  font-size: 1.35rem;
  color: #333333;
  letter-spacing: 3px;
  margin-top: 1.78rem;
}
.header-time{
  font-size: 0.64rem;
  color: #999999;
  letter-spacing: 2px;
  margin-bottom: 0.75rem;
}
.more-content-container{
  width: 40.64rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
}
.btn-container{
  display: flex;
  margin-bottom: 0.96rem;
}
.pre-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
  cursor: pointer;
}
.btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border: 1px #999999 solid;
  border-radius: 50%;
}
.txt{
  font-size: 0.85rem;
  color: #999999;
  letter-spacing: 3.5px;
}
.btn-txt{
  color: #999999;
  font-size: 1rem;
}
.next-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  cursor: pointer;
}
</style>
